import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index111111"} />
		<Helmet>
			<title>
				Разработка крутых приложений | Кодовое слово  
			</title>
			<meta name={"description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:title"} content={"Наши проекты | Кодовое слово  "} />
			<meta property={"og:description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/favicon.ico?v=2021-08-10T08:08:05.993Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
		</Helmet>
		<Box
			min-height="100px"
			background="#BAA9FF"
			width="100%"
			height="max-content"
			quarkly-title="page"
			display="flex"
			flex-wrap="wrap"
			justify-content="center"
			padding="40px 0px 140px 0px"
		>
			<Box
				min-width="100px"
				min-height="100px"
				quarkly-title="contentmain"
				width="100%"
				max-width="1200px"
				display="flex"
				flex-wrap="wrap"
				background="#b92e2e"
			>
				<Box
					min-height="383px"
					background="#ffffff"
					width="100%"
					max-width="600px"
					border-width="2px"
					border-style="solid"
					border-color="#060606"
					border-radius="8px"
					padding="40px 40px 40px 40px"
					height="max-content"
					quarkly-title="jobName"
				>
					<Image
						src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/Vector%2084.png?v=2022-01-19T12:30:27.306Z"
						display="flex"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						height="20px"
						width="38px"
						margin="0px 0px 40px 0px"
						quarkly-title="arrow"
					/>
					<Text
						margin="0px 0px 225px 0px"
						display="flex"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						font="400 32px --fontFamily-sansHelvetica"
						color="#000000"
						quarkly-title="H1"
					>
						wrwerwerwerwe werwerwer
					</Text>
					<Button
						display="block"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						position="relative"
						border-radius="8px"
						background="#000000"
						width="260px"
						height="56px"
						color="#ffffff"
						font="normal 700 14px/1.5 --fontFamily-sansHelvetica"
						text-transform="uppercase"
					>
						Button
					</Button>
				</Box>
				<Box
					min-height="383px"
					background="#ffffff"
					width="100%"
					max-width="600px"
					border-width="2px"
					border-style="solid"
					border-color="#060606"
					border-radius="8px"
					padding="40px 40px 40px 40px"
					height="max-content"
					quarkly-title="about"
				>
					<Image
						src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/Vector%2084.png?v=2022-01-19T12:30:27.306Z"
						display="flex"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						height="20px"
						width="38px"
						margin="0px 0px 40px 0px"
					/>
					<Text
						margin="0px 0px 0px 0px"
						display="flex"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						font="400 32px --fontFamily-sansHelvetica"
						color="#000000"
					>
						wrwerwerwerwe werwerwer
					</Text>
					<Button
						display="flex"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						bottom="0px"
						position="absolute"
					>
						Button
					</Button>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				quarkly-title="content3"
				width="100%"
				max-width="1200px"
				display="flex"
				flex-wrap="wrap"
				background="#1111d5"
			>
				<Box
					background="#ffffff"
					width="100%"
					max-width="600px"
					border-radius="8px"
					padding="0px 0px 0px 0px"
					quarkly-title="jobName"
					min-height={0}
					height={0}
				/>
				<Box
					min-width="100px"
					min-height="100px"
					quarkly-title="content2"
					width="100%"
					max-width="600px"
					display="flex"
					flex-wrap="wrap"
				>
					<Box
						min-height="383px"
						background="#ffffff"
						width="100%"
						max-width="600px"
						border-width="2px"
						border-style="solid"
						border-color="#060606"
						border-radius="8px"
						padding="40px 40px 40px 40px"
						height="max-content"
						quarkly-title="jobName"
					>
						<Image
							src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/Vector%2084.png?v=2022-01-19T12:30:27.306Z"
							display="flex"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							height="20px"
							width="38px"
							margin="0px 0px 40px 0px"
							quarkly-title="arrow"
						/>
						<Text
							margin="0px 0px 225px 0px"
							display="flex"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							font="400 32px --fontFamily-sansHelvetica"
							color="#000000"
							quarkly-title="H1"
						>
							wrwerwerwerwe werwerwer
						</Text>
						<Button
							display="block"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							position="relative"
							border-radius="8px"
							background="#000000"
							width="260px"
							height="56px"
							color="#ffffff"
							font="normal 700 14px/1.5 --fontFamily-sansHelvetica"
							text-transform="uppercase"
						>
							Button
						</Button>
					</Box>
					<Box
						min-height="383px"
						background="#ffffff"
						width="100%"
						max-width="600px"
						border-width="2px"
						border-style="solid"
						border-color="#060606"
						border-radius="8px"
						padding="40px 40px 40px 40px"
						height="max-content"
						quarkly-title="about"
					>
						<Image
							src="https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/Vector%2084.png?v=2022-01-19T12:30:27.306Z"
							display="flex"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							height="20px"
							width="38px"
							margin="0px 0px 40px 0px"
						/>
						<Text
							margin="0px 0px 0px 0px"
							display="flex"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							font="400 32px --fontFamily-sansHelvetica"
							color="#000000"
						>
							wrwerwerwerwe werwerwer
						</Text>
						<Button
							display="flex"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							bottom="0px"
							position="absolute"
						>
							Button
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60feb3ae63f5070017af70c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"619b78f310dffeaeba606725"} />
			<style place={"endOfBody"} rawKey={"619b7917f5e4ef1492682e06"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"620d0b13860a3cd523791a26"}>
				{"<!-- Yandex.Metrika counter -->\n<script type=\"text/javascript\" >\n   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(87531028, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true,\n        webvisor:true\n   });\n</script>\n<noscript><div><img src=\"https://mc.yandex.ru/watch/87531028\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div></noscript>\n<!-- /Yandex.Metrika counter -->"}
			</script>
		</RawHtml>
	</Theme>;
});